<template>
  <div class="newPurchasingTable_box">
    <div class="table_box">
      <el-table
        :data="tableData"
        stripe
        style="
          width: 100%;
          margin: 0 auto;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
        "
        :header-cell-style="getHeaderCellStyle"
      >
        <el-table-column prop="isEnd" label="状态" width="180">
          <template #default="scope">
            <div
              style="
                display: flex;
                align-items: center;
                font-size: 18px;
                margin-left: 30px;
              "
            >
              <span
                :style="!scope.row.isEnd ? 'color:#E6A340' : 'color:#009FFF'"
                >{{ scope.row.isEnd ? "进行中" : "已截止" }}</span
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          label="公告名称"
          :show-overflow-tooltip="true"
          class-name="nameFontRegular"
        />
        <el-table-column
          prop="tenderCompanyName"
          label="招标单位"
          class-name="nameFontRegular"
        />
        <el-table-column prop="secondLevelDictName" label="类别">
          <template #default="row">
            <div style="display: flex; align-items: center; text-align: center">
              <el-tag
                style="font-size: 18px; font-weight: bold; padding: 10px"
                size="Large
"
                >{{ row.row.secondLevelDictName }}</el-tag
              >
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="Htmlcontent" label="内容" width="180">
      <template #default="row">
        <div style="display: flex; align-items: center">
          <span
            style="color: #009fff; cursor: pointer"
            @click.prevent="handleClick(row, row.$index)"
          >
            查看详情</span
          >
        </div>
      </template>
    </el-table-column> -->

        <el-table-column
          prop="publishTime"
          label="发布时间"
          class-name="fontRegular"
        />
        <el-table-column
          prop="endTime"
          label="截止时间"
          class-name="fontRegular"
        />

        <el-table-column fixed="right" label="操作" width="120">
          <template #default="row">
            <el-button
              type="primary"
              plain
              size="large"
              @click.prevent="handleClick(row)"
              style="font-weight: bold"
            >
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page_box">
      <el-pagination
        style="justify-content: flex-end; margin: 20px 10px"
        v-model:currentPage="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :background="true"
        layout="total,prev, pager, next, sizes,jumper"
        :total="tableTotal"
        @size-change="ChangeSize"
        @current-change="ChangePage"
      />
      <!-- :total="tableDataLength == 0 ? 100 : tableDataLength" -->
    </div>
  </div>
</template>

<script>
import { reactive, computed, onBeforeMount } from "vue";
import { stateStore, PurchasingTableStore } from "@/store";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { getTableList } from "@/api/index";
export default {
  setup() {
    const store = PurchasingTableStore();
    onBeforeMount(async () => {
      const res = await getTableList({
        current: store.page || 1,
        size: store.size || 10,
        name: store.name,
        // tenderProjectClassifyCodeFirst: ["B", "C"],
        type: store.type || "tender_bulletin",
        descs: "", //倒序字段
        ascs: "", //正序字段
        tradeClassification: "D",
      });
      console.log(res, "res");
      store.setData("total", res.data.total);
      store.setData("data", res.data.records);
      if (!store.type) {
        store.setData("type", "tender_bulletin");
      }
    });
    const router = useRouter();
    const tableData = computed(() => storeToRefs(store).loadData);
    const tableTotal = computed(() => storeToRefs(store).total);
    const handleClick = (row) => {
      stateStore().setactiveHtml(
        row.row.content,
        row.row.name,
        row.row.publishTime,
        row.row
      );
      window.open(
        router.resolve({
          path: "/BiddingInfo",
          query: { id: row.row.id, type: "货物采购信息详情" },
        }).href,
        "_blank"
      );
    };
    const ChangeSize = (value) => {
      store.setData("size", value);
      store.reloadTableData();
    };
    const ChangePage = (value) => {
      store.setData("page", value);
      store.setData("currentPage", value);
      store.reloadTableData();
    };
    const currentPage = computed(() => storeToRefs(store).currentPage);
    const pageSize = computed(() => storeToRefs(store).size);

    const data = reactive({
      currentPage,
      pageSize,
      tableData,
      tableTotal,
      ChangeSize,
      ChangePage,
    });
    const getHeaderCellStyle = () => {
      return { color: "#000", padding: "0 30px" };
    };
    return {
      ...data,
      handleClick,
      // loadData,
      getHeaderCellStyle,
    };
  },
};
</script>

<style scoped>
@import url("./newPurchasingTable.css");
</style>

